import React, { createContext, useState, ReactNode, useEffect } from 'react'

import { getLiveEvent } from '../utils/liveEvent';
import { getSpeakers, getSideSpeakers } from './utils'

const DynamicContext = createContext({
	liveSpeaker: null,
	speakerLinks: null,
	sideLiveSpeaker: null,
	sideSpeakerLinks: null
})

const DynamicProvider = ({ children }) => {
	const [liveSpeaker, setLiveSpeaker] = useState(null)
	const [speakerLinks, setSpeakerLinks] = useState(null);
	const [sideSpeakerLinks, setSideSpeakerLinks] = useState(null);
	const [sideLiveSpeaker, setSideLiveSpeaker] = useState(null);

	//Set an interval for getting the current time for live updates on events and initialize the scrollbar
  useEffect(() => {
    const intervalId = setInterval(() => {
      getSpeakers().then(speakers => {
				//Get the current live event
				const nowLive = getLiveEvent(speakers);

				//Get updated link of speaker that have a video link
				const spekaersWithLink = speakers.filter((item : any) => item.linkAfterLive != "" && item.linkAfterLive != null).map((x : any) => { return ({ "slug" : x.slug, "link": x.linkAfterLive})});
				setSpeakerLinks(spekaersWithLink);
		
				if(nowLive[0]) {
					setLiveSpeaker(nowLive[0])
				}
			})

			getSideSpeakers().then(sideSpeakers => {
				//Get the current live event
				const nowSideLive = getLiveEvent(sideSpeakers);
				
				//Get updated link of speaker that have a video link
				const spekaersWithLink = sideSpeakers.filter((item : any) => item.linkAfterLive != "" && item.linkAfterLive != null).map((x : any) => { return ({ "slug" : x.slug, "link": x.linkAfterLive})});
				setSideSpeakerLinks(spekaersWithLink);
		
				if(nowSideLive[0]) {
					setSideLiveSpeaker(nowSideLive[0])
				}
			})
    }, 60*1*1000);
    
		getSpeakers().then(speakers => {
			//Get the current live event
			const nowLive = getLiveEvent(speakers);
			
			//Get updated link of speaker that have a video link
			const spekaersWithLink = speakers.filter((item : any) => item.linkAfterLive != "" && item.linkAfterLive != null).map((x : any) => { return ({ "slug" : x.slug, "link": x.linkAfterLive})});
			setSpeakerLinks(spekaersWithLink);
	
			if(nowLive[0]) {
				setLiveSpeaker(nowLive[0])
			}
		})

		getSideSpeakers().then(sideSpeakers => {
			//Get the current live event
			const nowSideLive = getLiveEvent(sideSpeakers);
			
			//Get updated link of speaker that have a video link
			const spekaersWithLink = sideSpeakers.filter((item : any) => item.linkAfterLive != "" && item.linkAfterLive != null).map((x : any) => { return ({ "slug" : x.slug, "link": x.linkAfterLive})});
			setSideSpeakerLinks(spekaersWithLink);
	
			if(nowSideLive[0]) {
				setSideLiveSpeaker(nowSideLive[0])
			}
		})
		
    return () => {
      clearInterval(intervalId)
		}
		
  }, [])

	return (
		<DynamicContext.Provider
			value={{
				liveSpeaker: liveSpeaker,
				speakerLinks: speakerLinks,
				sideLiveSpeaker: sideLiveSpeaker,
				sideSpeakerLinks: sideSpeakerLinks
			}}
		>
			{children}
		</DynamicContext.Provider>
	)
}

export default DynamicContext

export { DynamicProvider }